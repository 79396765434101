/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/admin/dashboard",
    name: "Dashboard",
    slug: "dashboard",
    icon: "MonitorIcon",
    i18n: "Dashboard"
  },
  {
    url: "/admin/user-management",
    name: "User Management",
    slug: "user-management",
    icon: "UsersIcon",
    i18n: "Users"
  },
  // {
  //   url: "/admin/subs-management",
  //   name: "Subscription Management",
  //   slug: "subs-management",
  //   icon: "CalendarIcon",
  //   i18n: "Subscribers"
  // },
  {
    url: "/admin/car-dealer",
    name: "Car Dealers",
    slug: "car-dealers",
    icon: "DollarSignIcon",
    i18n: "Car Dealers"
  },
  {
    url: null,
    name: "Vehicles",
    slug: "vehicle",
    icon: "TruckIcon",
    i18n: "Vehicles",
    submenu: [
      {
        url: "/admin/vehicle/pending",
        name: "Pending",
        slug: "vehicle-pending",
        icon: "TruckIcon",
        i18n: "Pending"
      },
      {
        url: "/admin/vehicle/approved",
        name: "Approved",
        slug: "vehicle-approved",
        icon: "TruckIcon",
        i18n: "Approved"
      },
      {
        url: "/admin/vehicle/rejected",
        name: "Rejected",
        slug: "vehicle-rejected",
        icon: "TruckIcon",
        i18n: "Rejected"
      },
      {
        url: "/admin/rejected-vehicles",
        name: "Rejected Vehicles",
        slug: "rejected-vehicles",
        icon: "TruckIcon",
        i18n: "Rejected Vehicles"
      },
      {
        url: "/admin/vehicle/auction-completed",
        name: "Auctions Completed",
        slug: "vehicle-auction-completed",
        icon: "TruckIcon",
        i18n: "Auctions Completed"
      },
    ]
  },
  {
    url: null,
    name: "Auctions",
    slug: "auctions",
    icon: "ShoppingBagIcon",
    i18n: "Auctions",
    submenu: [
      {
        url: "/admin/auction/live",
        name: "Live",
        slug: "vehicle-pending",
        icon: "TruckIcon",
        i18n: "Live"
      },
      {
        url: "/admin/auction/completed",
        name: "Completed",
        slug: "vehicle-approved",
        icon: "TruckIcon",
        i18n: "Completed"
      }
    ]
  },
  {
    url: "/admin/market",
    name: "Markets",
    slug: "market",
    icon: "TargetIcon",
    i18n: "Markets"
  },
  {
    url: "/admin/make-management",
    name: "Make Management",
    slug: "make-management",
    icon: "DatabaseIcon",
    i18n: "Car Datasets"
  },
  {
    url: "/admin/colors",
    name: "External Colors",
    slug: "external-color",
    icon: "CircleIcon",
    i18n: "Exernal Colors"
  },
  {
    url: "/admin/email-template",
    name: "Email Templates",
    slug: "email-templates",
    icon: "MailIcon",
    i18n: "Emails"
  },
  {
    url: "/admin/cms-pages",
    name: "Page Management",
    slug: "page-management",
    icon: "FileIcon",
    i18n: "Pages"
  },

  {
    url: "/admin/report",
    name: "Report Management",
    slug: "report-management",
    icon: "FileTextIcon",
    i18n: "Reports"
  },
  {
    url: "/admin/log-lead",
    name: "Log of Leads",
    slug: "log-lead",
    icon: "FileTextIcon",
    i18n: "Log of Leads"
  },
  {
    url: "/admin/faqs",
    name: "FAQs",
    slug: "FAQs",
    icon: "HelpCircleIcon",
    i18n: "FAQs"
  },

  {
    url: "/admin/settings",
    name: "Settings",
    slug: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings"
  }
];
