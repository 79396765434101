<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">
    <!-- <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
       <small>Available</small>  
    </div>-->

    <vs-dropdown vs-custom-content class="cursor-pointer">
      <div class="text-right leading-tight hidden sm:block">
        <vs-button color="danger" type="flat">
          <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
          {{ activeUserInfo.displayName }}
        </vs-button>
        <!-- <span class="font-semibold">{{ activeUserInfo.displayName }}</span>
        <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />-->
        <!-- <small>Available</small>  -->
      </div>
      <!-- <div class="con-img ml-3">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>-->

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push({name: 'admin-profile'}).catch(() => {})"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/change-password').catch(() => {})"
          >
            <feather-icon icon="KeyIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Change Password</span>
          </li>
          <!--<li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/profile').catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/email').catch(() => {})">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/todo').catch(() => {})">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/chat').catch(() => {})">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/eCommerce/wish-list').catch(() => {})">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li>
          -->
          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";

  export default {
    data() {
      return {};
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser;
      },
    },
    methods: {
      logout() {
        // if user is logged in via auth0
        if (this.$auth.profile) this.$auth.logOut();

        // if user is logged in via firebase
        // const firebaseCurrentUser = firebase.auth().currentUser;

        // if (firebaseCurrentUser) {
        //   firebase
        //     .auth()
        //     .signOut()
        //     .then(() => {
        //       this.$router.push("/login").catch(() => {});
        //     });
        // }
        // If JWT login
        if (localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`)) {
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`);
          localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`);
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`);
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`);
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`);

      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change("admin");
      localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}UserInfo`);

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push("/admin/login").catch(() => {});
    },
  },
  };
</script>
